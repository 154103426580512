<template>
  <b-row>
    <b-col md="12">
      <navbar />
      <router-view />

      <site-footer />
    </b-col>
  </b-row>

  <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import SiteFooter from './partials/SiteFooter.vue'
import Navbar from './partials/Navbar.vue'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/animate.css'
import '@/assets/css/icofont.min.css'
import '@/assets/css/magnific-popup.css'
import '@/assets/css/meanmenu.css'
import '@/assets/style.css'
import '@/assets/css/responsive.css'

export default {
  components: {
    // AppCustomizer,
    BRow,
    BCol,
    Navbar,
    SiteFooter,

  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
