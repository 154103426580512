<template>
  <div>
    <!-- Navbar Area Start -->
    <div class="navbar-area">
      <!-- For Mobile Device -->
      <div class="mobile-nav">
        <a
          href="index.html"
          class="logo"
        >
          <img
            src="@/assets/images/logomameri.png"
            alt="logo"
          >
        </a>
      </div>
      <!-- For Desktop Device -->
      <div class="main-nav">
        <div class="container">
          <nav
            class="navbar navbar-expand-lg navbar-light"
            style="margin-top:-20px;"
          >
            <router-link
              class="navbar-brand"
              to="/"
            >
              <img
                src="@/assets/images/logomameri.png"
                alt="logo"
                width=""
                height="70px"
              >
            </router-link>
            <div
              id="navbarSupportedContent"
              class="collapse navbar-collapse mean-menu"
            >
              <ul
                class="navbar-nav ml-auto"
                style="font-size: 15px;"
              >
                <li class="nav-item">
                  <router-link
                    to="/"
                    class="nav-link"
                  >
                    Accueil
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/affreteurs"
                    class="nav-link"
                  >
                    Affreteur
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/transporteurs"
                    class="nav-link"
                  >
                    Transporteur
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/contact"
                    class="nav-link"
                  >
                    Contact
                  </router-link>
                </li>
                <li class="nav-item ml-1">
                  <router-link
                    to="/register"
                    class="nav-link"
                  >
                    <feather-icon
                      icon="UserPlusIcon"
                      size="16"
                      class="align-middle text-body"
                    /> S'inscrire
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/login"
                    class="nav-link"
                  >
                    <feather-icon
                      icon="LogInIcon"
                      size="16"
                      class="align-middle text-body"
                    />  Se Connecter
                  </router-link>
                </li>

              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  components: { },
  data() {
    return {
      user: null,
    }
  },
  created() {
  },
  methods: {

  },
}
</script>
